
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { config } from "@/core/helpers/config";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import ReceiptsTable from "@/components/reusable/ReceiptsTable.vue";
import InvoicesTable from "@/components/reusable/InvoicesTable.vue";
import PolicySummary from "@/components/reusable/policy-information/CooperativeUmbrellaPolicySummary.vue";

export default defineComponent({
  name: "cooperative-motor-insurance-policy-information",
  components: {
    Form,
    Field,
    ErrorMessage,
    ReceiptsTable,
    InvoicesTable,
    PolicySummary
  },
  data() {
    return {
      policySummary: {},
      receipts: [],
      invoices: [],
      policies: [],
      publicId: this.$route.params.publicId,
      memberPublicId: ""
    };
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");

      setCurrentPageBreadcrumbs("Motor Insurance", [
        "Cooperative",
        "Policies",
        "Policy Information"
      ]);
      MenuComponent.reinitialization();
    });

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      config,
      setActiveTab
    };
  },
  created() {
    this.policyInformation();

    this.displayDatatable();

    // Set page title
    document.title =
      "Cooperative Admin: Members Motor Insurance Policies | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    policyInformation() {
      // Fetch the members policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.COOPERATIVE_UMBRELLA_POLICY_ROUTE}/${this.publicId}`
        )
          .then(({ data }) => {
            // Assign response to array variable
            this.policies = data.data.policies;
            this.policySummary = data.data.policySummary;
            this.receipts = data.data.receipts;
            this.invoices = data.data.invoices;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)"
          },
          processing: true
        });
      }, 3000);
    },
    gotoUrl(event) {
      this.$router.push(event);
    }
  }
});
